import React from 'react';
import { Col, Row } from "react-bootstrap";
import { StyledSectionDarkSansSerif } from "./styles/Section.styled";
import { StyledLink, StyledP } from "./styles/Footer.styled";
import { useTranslation } from 'react-i18next'; 

const Footer = () => {
    const { t } = useTranslation(); 

    return (
        <StyledSectionDarkSansSerif>
            <Row md={12}>
                <Col md={4} style={{ padding: "50px" }}>
                    © 2025 {t('footer.rights')}
                </Col>
                <Col md={4} style={{ padding: "50px" }}>
                    <h4>{t('footer.ourCompany')}</h4>
                    <ul>
                        <li>
                            <StyledLink to={"/TerminoDeUso"}>
                                {t('footer.termsOfUse')}
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink to={"/PoliticaDePrivacidad"}>
                                {t('footer.privacyPolicy')}
                            </StyledLink>
                        </li>
                    </ul>
                </Col>
                <Col md={4} style={{ padding: "50px" }}>
                    <h4>{t('footer.contact')}</h4>
                    <strong>{t('footer.email')}:</strong>
                    <StyledP>
                        <a href="mailto:contacto@anguitaosorio.cl"
                           title="contacto">{t('footer.emailValue')}</a>
                    </StyledP>

                    <strong>{t('footer.phone')}:</strong>
                    <StyledP>
                        <a href="tel:+56227603597" title="+56 2 2760 4512">{t('footer.phoneValue')}</a>
                    </StyledP>

                    <strong>{t('footer.location')}:</strong>
                    <p>
                        {t('footer.address')}
                    </p>
                </Col>
            </Row>
        </StyledSectionDarkSansSerif>
    );
};

export default Footer;
