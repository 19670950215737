import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Nosotros from "./pages/Nosotros";
import GlobalStyle from "./components/styles/Global.styled";
import Practica from "./pages/Practica";
import Equipo from "./pages/Equipo";
import EA from "./pages/Eduardoanguita";
import AA from "./pages/Angelanguita";
import NK from "./pages/NathalieKoenig";
import AM from "./pages/AndresMatheus";
import PF from "./pages/PatricioFredes";
import MV from "./pages/MariaVictoriaSmith";
import KA from "./pages/KaremAlexander";
import JT from "./pages/JoseTomasCuevas";
import FN from "./pages/FranciscaNunez";
import Contacto from "./pages/Contacto";
import Noticias from "./pages/Noticias";
import Noticia from "./pages/Noticia";
import TerminoDeUso from "./pages/TerminoDeUso";
import PoliticaDePrivacidad from "./pages/PoliticaDePrivacidad";
import { getWebsiteData } from "./data/Website";
import CookieBanner from "./components/CookieBanner";
import ScrollToTop from "./components/ScrollToTop";
import ReactGA from 'react-ga4';
import i18n from "./components/i18n";
import usePageTracking from "./components/usePageTracking";

function App() {
    let current_language = "ES";
    const location = useLocation();

    usePageTracking();

    // Evento para Google Analytics cuando se carga el iframe
    useEffect(() => {
        if (location.pathname === '/next/leyDelitosEconomicos.html') {
            ReactGA.event({
                category: 'Navigation',
                action: 'Iframe Loaded',
                label: '/next/leyDelitosEconomicos.html',
            });
        }
    }, [location.pathname]);

    // Forzar recarga en rutas específicas
    useEffect(() => {
        const pathsToReload = [
            '/next/leyDelitosEconomicos.html',
            '/next/leyProteccionDatos.html' // Puedes añadir más rutas aquí
        ];
        if (pathsToReload.includes(location.pathname)) {
            window.location.reload();
        }
    }, [location.pathname]);

    return (
        <>
            <GlobalStyle />
            <CookieBanner />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Nosotros" element={<Nosotros />} />
                <Route
                    path="/Corporativo"
                    element={
                        <Practica
                            id="corporativo"
                            title={getWebsiteData("corporativo", current_language).title}
                            content={getWebsiteData("corporativo", current_language).content}
                            src={getWebsiteData("corporativo", current_language).image}
                        />
                    }
                />
                <Route
                    path="/VC"
                    element={
                        <Practica
                            id="vc"
                            title={getWebsiteData("vc", current_language).title}
                            content={getWebsiteData("vc", current_language).content}
                            src={getWebsiteData("vc", current_language).image}
                        />
                    }
                />
                <Route
                    path="/Compliance"
                    element={
                        <Practica
                            id="compliance"
                            title={getWebsiteData("compliance", current_language).title}
                            content={getWebsiteData("compliance", current_language).content}
                            src={getWebsiteData("compliance", current_language).image}
                        />
                    }
                />
                <Route
                    path="/LeyDelitosEconomicos"
                    element={
                        <iframe
                            src="/next/leyDelitosEconomicos.html"
                            style={{ width: "100%", height: "100vh", border: "none" }}
                        />
                    }
                />
                <Route
                    path="/Legalops"
                    element={
                        <Practica
                            id="legalops"
                            title={getWebsiteData("legalops", current_language).title}
                            content={getWebsiteData("legalops", current_language).content}
                            src={getWebsiteData("legalops", current_language).image}
                        />
                    }
                />
                <Route
                    path="/Legaltech"
                    element={
                        <Practica
                            id="legaltech"
                            title={getWebsiteData("legaltech", current_language).title}
                            content={getWebsiteData("legaltech", current_language).content}
                            src={getWebsiteData("legaltech", current_language).image}
                        />
                    }
                />
                <Route
                    path="/Outsourcing"
                    element={
                        <Practica
                            id="outsourcing"
                            title={getWebsiteData("outsourcing", current_language).title}
                            content={getWebsiteData("outsourcing", current_language).content}
                            src={getWebsiteData("outsourcing", current_language).image}
                        />
                    }
                />
                <Route path="/Equipo" element={<Equipo />} />
                <Route path="/Eduardoanguita" element={<EA />} />
                <Route path="/Angelanguita" element={<AA />} />
                <Route path="/Nathaliekoenig" element={<NK />} />
                <Route path="/Andresmatheus" element={<AM />} />
                <Route path="/Patriciofredes" element={<PF />} />
                <Route path="/Mariavictoriasmith" element={<MV />} />
                <Route path="/Karemalexander" element={<KA />} />
                <Route path="/Josetomascuevas" element={<JT />} />
                <Route path="/Franciscanunez" element={<FN />} />
                <Route path="/Contacto" element={<Contacto />} />
                <Route path="/Noticias" element={<Noticias />} />
                <Route path="/Noticias/:url" element={<Noticia />} />
                <Route path="/TerminoDeUso" element={<TerminoDeUso />} />
                <Route path="/PoliticaDePrivacidad" element={<PoliticaDePrivacidad />} />
            </Routes>
        </>
    );
}

export default App;
